@import "var";

.mat-actions {
  .mat-raised-button {
    margin-right: 15px;
    margin-bottom: 15px;
    display: block;
  }
}
.mat-button,
.mat-raised-button {
  line-height: 1 !important;
  font-size: 1.6rem !important;
  text-decoration: none !important;
  box-shadow: none !important;
  margin-right: 8px !important;
  font-weight: 400 !important;
  position: relative !important;
  min-width: 160px !important;
}
.mat-button[disabled],
.mat-raised-button[disabled] {
  cursor: not-allowed !important;
}
.mat-button:focus,
.mat-raised-button:focus {
  outline: 3px solid $ops-orange-fca !important;
  transition: all 0.1s ease-in-out !important;
}
.mat-raised-button {
  &.mat-primary {
    background-color: $ops-blue-007 !important;
    color: $ops-white !important;
    padding: 12px 12px;
    &:not([disabled]):hover {
      background-color: $ops-blue-0047 !important;
    }
    &:not([disabled]):active {
      background-color: $ops-grey-002 !important;
    }
  }
  &.mat-secondary {
    background-color: $ops-white !important;
    border: 1px solid $ops-blue-006 !important;
    color: $ops-blue-006 !important;
    padding: 15px 23px !important;
    &:not([disabled]):hover {
      background-color: $ops-grey-eb0 !important;
    }
    &:not([disabled]):active {
      background-color: $ops-grey-c00 !important;
    }
  }
  &.mat-accent {
    background-color: $ops-white !important;
    border: 1px solid $ops-blue-006 !important;
    color: $ops-blue-006 !important;
    padding: 15px 23px !important;
    &:not([disabled]):hover {
      background-color: $ops-grey-eb0 !important;
    }
    &:not([disabled]):active {
      background-color: $ops-grey-c00 !important;
    }
  }
}
.mat-raised-button[disabled] {
  background-color: $ops-grey-d90 !important;
}
