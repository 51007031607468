@use '1-variables/colours.variables' as colours;

$ops-white: colours.$ontario-colour-white;
$ops-grey-ef0: #efefef;
$ops-grey-eb0: #ebebeb;
$ops-grey-ed0: #ededed;
$ops-grey-d90: #d9d9d9;
$ops-grey-c00: #CCCCCC;
$ops-grey-b00: #bbb;
$ops-grey-800: #888;
$ops-grey-600: #666;
$ops-grey-4d0: #4d4d4d;
$ops-grey-4e0: #4e4e4e;
$ops-grey-300: #333;
$ops-black: colours.$ontario-colour-black;
$ops-grey-002: #002142;

$ops-sans: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$ops-red-fa0: #fad2d2;
$ops-red-d60: #d62929;


$ops-orange-ff0: #ff8900;
$ops-orange-f20: #f2671f;
$ops-orange-fca: #fcaf17;

$ops-yellow-fa0: #fafad2;
$ops-yellow-ff0: #ffc300;

$ops-green-e00: #e6f8d2;
$ops-green-000: #008b52;

$ops-purple-600: #8138b3;

$ops-magenta-900: #9c0f5f;

$ops-blue-df0: #dff3f3;
$ops-blue-d10: #d1ddeb;
$ops-blue-b00: #b2c6de;
$ops-blue-400: #4d90fe;
$ops-blue-500: #5798d8;
$ops-blue-008: #0083c4;
$ops-blue-007: colours.$ontario-colour-dark-blue;
$ops-blue-006: #0066cc;
$ops-blue-004: #004e99;
$ops-blue-0047: #00478F;

