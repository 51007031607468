@use '1-variables/colours.variables' as colours;
/**
 * @imports
 * http://mcg.mbitson.com
 * node-sass  --output-style compressed ./src/assets/styles/material.scss ./src/assets/styles/material.css
 */
@use '@angular/material' as mat;

@include mat.core();

$theme-blue: (
  50: #e0eef5,
  100: colours.$ontario-colour-light-blue,
  200: #80b8d4,
  300: #4d9cc3,
  400: #2686b6,
  500: colours.$ontario-colour-blue,
  600: #0069a2,
  700: colours.$ontario-colour-dark-blue,
  800: #00548f,
  900: #00427e,
  A100: #acd1ff,
  A200: #79b5ff,
  A400: #4698ff,
  A700: #2c8aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff
  )
);

$theme-green: (
  50: #e0ede8,
  100: #b3d3c5,
  200: #80b59f,
  300: #4d9779,
  400: #26815c,
  500: #006b3f,
  600: #006339,
  700: #005831,
  800: #004e29,
  900: #003c1b,
  A100: #72ffa2,
  A200: #3fff80,
  A400: #0cff5f,
  A700: #00f152,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$theme-red: (
  50: #fae5e5,
  100: #f3bfbf,
  200: #eb9494,
  300: #e26969,
  400: #dc4949,
  500: #d62929,
  600: #d12424,
  700: #cc1f1f,
  800: #c61919,
  900: #bc0f0f,
  A100: #ffeaea,
  A200: #ffb7b7,
  A400: #ff8484,
  A700: #ff6a6a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$theme-red: (
  50: #fae5e5,
  100: #f3bfbf,
  200: #eb9494,
  300: #e26969,
  400: #dc4949,
  500: #d62929,
  600: #d12424,
  700: #cc1f1f,
  800: #c61919,
  900: #bc0f0f,
  A100: #ffeaea,
  A200: #ffb7b7,
  A400: #ff8484,
  A700: #ff6a6a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Define a light theme
$light-primary: mat.define-palette($theme-blue);
$light-accent: mat.define-palette($theme-green);
$light-warn: mat.define-palette($theme-red);
$light-theme: mat.define-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
    warn: $light-warn
  )
));

@include mat.all-component-themes($light-theme);
