.table-basic {
  font-size: 1.4rem !important;
  border-radius: 0 !important;
  .table-row-head {
    background-color: $ops-grey-600 !important;
    color: $ops-white !important;
  }
  .table-row-body {
    border-bottom: 1px solid $ops-grey-ef0 !important;
  }
  .table-row-odd {
    background-color: $ops-grey-ef0 !important;
  }
  .table-row-click {
    &:hover {
      background-color: $ops-grey-d90 !important;
    }
  }
  td {
    white-space: initial !important;
  }
}
.table-pages .table-pages-one,
.table-pages .table-pages-prev,
.table-pages .table-pages-next {
  font-size: 1.4rem !important;
  color: $ops-blue-006 !important;
  border: $ops-grey-b00 1px solid !important;
  border-radius: 3px !important;
  margin-right: 5px !important;
  padding: 5px 7px !important;
}
.table-pages .table-pages-one:hover,
.table-pages .table-pages-prev:hover,
.table-pages .table-pages-next:hover {
  background: #f2f2f2 !important;
}
.table-pages .table-pages-one.table-pages-current,
.table-pages .table-pages-prev.table-pages-current,
.table-pages .table-pages-next.table-pages-current {
  background: $ops-blue-007 !important;
  border-color: $ops-blue-007 !important;
  color: $ops-white !important;
}
.table-pages .table-pages-one.table-pages-disable,
.table-pages .table-pages-prev.table-pages-disable,
.table-pages .table-pages-next.table-pages-disable,
.table-pages .table-pages-one.table-pages-disable:hover,
.table-pages .table-pages-prev.table-pages-disable:hover,
.table-pages .table-pages-next.table-pages-disable:hover {
  background: transparent !important;
  border-color: $ops-grey-b00 !important;
  color: $ops-grey-b00 !important;
}
.table-expanded-heading {
  border-bottom: 1px solid rgba(0,0,0,0.12);
  padding-bottom: 5px;
  display: block;
  color: #4f5b04;
}
.width-percent-10 {
  width: 10% !important;
}
.width-percent-15 {
  width: 15% !important;
}
.width-percent-30 {
  width: 30% !important;
}
.width-percent-40 {
  width: 40% !important;
}
