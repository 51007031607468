@use '1-variables/colours.variables' as colours;
@import "var";

body {
  padding-bottom: 50px;
}
.container {
  margin: 0 auto;
  padding: 30px 20px 0 20px;
  box-sizing: border-box;
  max-width: 1200px;
}

.app-container {
  padding-bottom: 50px;
  line-height: 1.5;
}

.inline {
  display: inline;
  a {
    font-size: 16px;
    display: inline;
  }
}
h1 {
  margin: 0 0 10px 0;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.02rem;
  padding-bottom: 0.5rem;
  &:focus {
    outline: none;
    outline-width: 0;
  }
}
h2,
h3,
h4,
caption {
  margin: 0 0 10px 0;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0.02rem;
  padding-bottom: 0.5rem;
}
caption {
  text-align: left;
  will-change: transform;
  caption-side: top;
}
span {
  &.label {
    font-weight: 600;
  }
}
.mat-ink-bar {
  height: 3px !important;
  outline: none !important;
}
.mat-mini-fab {
  &:focus {
    box-shadow: 0 0 0 4px colours.$ontario-colour-focus;
  }
}
.skip-to-main {
  font-size: 1.2rem !important;
  padding: 5px !important;
  opacity: 1 !important;
  color: $ops-blue-007 !important;
  font-weight: 600 !important;
}

