@import "var";

.mat-form-field,
.mat-radio-group,
.mat-checkbox {
  margin: 0 !important;
}
.small .mat-form-field,
.small .mat-radio-group,
.small .mat-checkbox {
  margin-bottom: 10px !important;
}
.mat-form-field {
  .mat-form-field-infix {
    border: $ops-black 1px solid !important;
  }
  .mat-form-field-underline {
    display: none !important;
  }
}
.mat-form-label,
.mat-checkbox-label {
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}
.mat-form-label span span,
.mat-checkbox-label span span {
  font-weight: 400 !important;
}
.mat-input-element {
  padding-left: 10px !important;
  &:focus {
    outline: -webkit-focus-ring-color 1px auto !important;
  }
}
.mat-form-field-label {
  font-weight: 400 !important;
  padding: 10px !important;
}
.mat-select {
  &:focus {
    outline: -webkit-focus-ring-color 1px auto !important;
  }
}
.mat-select-element {
  padding: 8px 10px !important;
}
.mat-checkbox-inner-container {
  margin: 0 !important;
  padding: 0 !important;
  margin-right: 10px !important;
  top: 4px !important;
  .mat-select-arrow {
    margin: 0 !important;
  }
}
.mat-calendar-table-header {
  color: inherit !important;
}
.fa-question-circle {
  color: $ops-grey-4d0;
}
.mat-has-prefix .mat-input-element,
.mat-has-prefix .mat-form-field-label,
.mat-has-prefix .mat-select-element {
  padding-left: 30px !important;
}
.mat-has-suffix .mat-input-element,
.mat-has-suffix .mat-form-field-label,
.mat-has-suffix .mat-select-element {
  padding-right: 30px !important;
}
.mat-form-field-prefix,
.mat-form-field-suffix {
  padding: 10px 7px !important;
}
.mat-datepicker-field {
  .mat-form-field-suffix {
    padding: 10px 9px !important;
  }
}
.mat-form-field-invalid {
  .mat-form-field-infix {
    border-color: $ops-red-d60 !important;
  }
}
.mat-form-field-invalid i,
.mat-form-field-invalid .mat-icon svg,
.mat-form-field-invalid .mat-icon-button svg {
  color: $ops-red-d60 !important;
  fill: $ops-red-d60 !important;
}
.mat-error {
  margin-top: 3px !important;
}
.mat-radio-button {
  &.mat-radio-disabled {
    .mat-radio-label-content {
      color: rgba(0,0,0,0.2);
    }
  }
}
dl {
  width: 100%;
}
dl dt,
dl dd {
  vertical-align: top;
  padding-bottom: 5px;
  display: inline-block;
  width: 49%;
}
.success {
  background-color: $ops-green-e00;
  padding: 10px 20px 12px 15px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 0 0 0 15px;
  border-color: rgba(0,0,0,0.1);
  box-sizing: border-box;
  position: relative;
  color: #333;
}
.error {
  background-color: $ops-red-fa0;
  padding: 10px 20px 12px 15px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 0 0 0 15px;
  border-color: rgba(0,0,0,0.1);
  box-sizing: border-box;
  position: relative;
  color: #333;
}
.general {
  background-color: $ops-grey-ed0;
  padding: 10px 20px 12px 15px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 0 0 0 15px;
  border-color: rgba(0,0,0,0.1);
  box-sizing: border-box;
  position: relative;
  color: #333;
}
.warning {
  background-color: $ops-yellow-fa0;
  padding: 10px 20px 12px 15px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 0 0 0 15px;
  border-color: rgba(0,0,0,0.1);
  box-sizing: border-box;
  position: relative;
  color: #333;
}
.spinner {
  margin: 10px 0 20px 0;
}
