@import "var";

* {
  border: 0;
  padding: 0;
  margin: 0;
  font-family: $ops-sans;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}
a,
button,
textarea,
.mat-select,
select,
input,
tr {
  background: transparent;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
  .mat-drawer-container {
    background-color: transparent !important;
    color: $ops-grey-4d0 !important;
  }
}
.title1,
.title2,
.title3,
.title4,
.title5 {
  margin: 0;
  margin-bottom: 5px;
  font-family: Raleway !important;
}
.title1 {
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 0.04rem;
}
.title2 {
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
}
.title3 {
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
}
.title4 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
}
.title5 {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
}
.description {
  margin-bottom: 20px;
}
//a {
//  text-decoration: none;
//  color: $ops-blue-006;
//  cursor: pointer;
//  &:hover {
//    text-decoration: underline;
//    color: $ops-blue-004;
//  }
//  &.active {
//    color: $ops-blue-006 !important;
//  }
//  &:visited {
//    color: $ops-purple-600;
//  }
//}
p {
  margin: 0;
}
ul {
  padding-left: 20px;
  &.ordered {
    list-style: decimal;
  }
  &.no-bullet {
    list-style: none;
    padding: 0;
  }
}
hr {
  background: $ops-grey-ed0;
  margin-bottom: 30px;
  margin-top: 30px;
  height: 1px;
  &.thick {
    margin-bottom: 30px;
    margin-top: 15px;
    height: 4px;
  }
  &.medium {
    margin-bottom: 30px;
    margin-top: 15px;
    height: 2px;
  }
}
.mat-aria-txt {
  font-size: 0;
}
