@use '1-variables/colours.variables' as colours;

/** @imports */
@import 'material';

@import 'assets/fonts/styles/font-awesome.min.css';
@import 'assets/fonts/styles/open-sans.css';

/** @exports */
@import 'var';
@import 'reset';
@import 'block';
@import 'buttons';
@import 'forms';
@import 'table';

// full width background
.full-width-gray-bg {
  background-color: colours.$ontario-greyscale-5;
  border-bottom: 5px solid colours.$ontario-colour-blue;
  padding-bottom: 3rem;
}

// form field
.mat-form-field {
  .mat-form-field-infix {
    border: #5a5a5a 1px solid !important;
  }
}
.mat-header-cell {
  font-size: initial;
  font-weight: 300;
  color: $ops-black;
}

a {
  text-decoration: none;
  cursor: pointer;
}
